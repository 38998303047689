var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"electoralDistrictForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"name":"The Governorate","rules":"required","vid":"governorate_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Governorate *',"list":_vm.governorateList,"listItemLabel":'name',"listItemValue":'id',"clearable":"","filterable":"","name":"governorate_id","placeholder":"Governorate","size":"large"},model:{value:(_vm.formData.governorate_id),callback:function ($$v) {_vm.$set(_vm.formData, "governorate_id", $$v)},expression:"formData.governorate_id"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"required","name":"The name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Name *","name":"name","fou":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"","name":"The english name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"english Name","name":"en_name","fou":""},model:{value:(_vm.formData.en_name),callback:function ($$v) {_vm.$set(_vm.formData, "en_name", $$v)},expression:"formData.en_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"","name":"The french name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"french Name","name":"fr_name","fou":""},model:{value:(_vm.formData.fr_name),callback:function ($$v) {_vm.$set(_vm.formData, "fr_name", $$v)},expression:"formData.fr_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"","name":"The spanish name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"spanish Name","name":"sp_name","fou":""},model:{value:(_vm.formData.es_name),callback:function ($$v) {_vm.$set(_vm.formData, "es_name", $$v)},expression:"formData.es_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"percentage_2018","rules":"","name":"The 2018 Percentage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":"2018 Percentage","name":"percentage_2018","min":"0","max":"100","fou":""},model:{value:(_vm.formData.percentage_2018),callback:function ($$v) {_vm.$set(_vm.formData, "percentage_2018", $$v)},expression:"formData.percentage_2018"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"total_2018","rules":"","name":"The 2018 total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":"Quotient 2018 (حاصل 2018)","name":"total_2018","fou":""},model:{value:(_vm.formData.total_2018),callback:function ($$v) {_vm.$set(_vm.formData, "total_2018", $$v)},expression:"formData.total_2018"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"total","rules":"","name":"The total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":"Quotient 2022 (حاصل 2022)","name":"total","fou":""},model:{value:(_vm.formData.total),callback:function ($$v) {_vm.$set(_vm.formData, "total", $$v)},expression:"formData.total"}})]}}],null,true)})],1),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_vm._v("  "),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/electoral-districts/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }